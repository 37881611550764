// const COCK_FIGHTING_API_URL = "http://localhost:443";
const COCK_FIGHTING_API_URL = "https://api.cockboxing.live:3200";
// const COCK_FIGHTING_API_URL = "https://api.cockboxing.live";

// -------------------- Asset --------------------
export async function GetAssets(params, body) {
  let response;
  try {
    response = await fetch("/v1/assets/filter" + params, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAsset(id) {
  let response;
  try {
    response = await fetch("/v1/asset/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAssetsByCollection(id, params) {
  let response;
  try {
    response = await fetch("/v1/assets/collection/" + id + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAssetsByFilter(id, body) {
  let response;
  try {
    response = await fetch("/v1/assets/collection/filter/" + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- USER --------------------
export async function GetUsers(params, body, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/allAdmin" + params, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(body),

    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAdminById(id, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/admin/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function CreateAdmin(body, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/admin/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function UpdateAdmin(id, body, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/admin/update/" + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function DeleteAdmin(id, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/admin/delete/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Login(body) {
  let response;
  try {
    response = await fetch("/v1/admin/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function SignatureLogin(signature) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/admin/signatureLogin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        signature: signature,
      },
      // body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAdmin(token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/admin", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token,
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- COLLECTION --------------------
export async function CreateCollection(body) {
  let response;
  try {
    response = await fetch("/v1/createCollection", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function UpdateCollection(id, body) {
  let response;
  try {
    response = await fetch("/v1/collection/" + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetCollections(query, body) {
  let response;
  try {
    response = await fetch("/v1/fight/admin/all" + query, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetCollection() {
  let response;
  try {
    response = await fetch("/v1/fight/admin/fights", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function VerifyCollection(id, token) {
  let response;
  try {
    response = await fetch("/v1/collection/verify/" + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        Authorization: token,
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- COMMENT --------------------
export async function GetComments(id) {
  let response;
  try {
    response = await fetch("/v1/comments/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- Reports --------------------
export async function GetReports(params, body) {
  let response;
  try {
    response = await fetch("/v1/reports/" + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(body)
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetReport(id) {
  let response;
  try {
    response = await fetch("/v1/report/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(body)
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// -------------------- COMMENT --------------------
export async function GetCategories() {
  let response;
  try {
    response = await fetch("/v1/GetCategories?limit=100", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetIdoToken(id, params, body) {
  console.log(params, "prms");
  let response;
  try {
    response = await fetch(`/emp/getAssetssAdmin?categoryId=${id}` + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// export async function GetIdoToken(params,body) {
//   console.log(params,"prms")
//   let response;
//   try {
//     response = await fetch(`/emp/getAssetssAdmin?categoryId=62fb3e94386a50bdef66cdf2`+params , {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Headers": "Content-Type",
//         "Access-Control-Allow-Origin": "*",
//       },
//     });
//   } catch (e) {}

//   if (response.status === 200) {
//     return await response.json();
//   } else {
//     let message = await response.json();
//     throw new Error(message.error);
//   }
// }
export async function GetFinancialAssets(id, query) {
  let response;
  try {
    response = await fetch(`/emp/getAssetss?categoryId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetInitialNFTofferings(id, query) {
  let response;
  try {
    response = await fetch(`/emp/getAssetssAdmin?categoryId=${id}` + query, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetManagedFunds(id) {
  let response;
  try {
    response = await fetch(`/emp/getAssetss?categoryId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetIdoTokenById(id) {
  let response;
  try {
    response = await fetch(`/emp/GetassetById/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetInoTokenById(id) {
  let response;
  try {
    response = await fetch(`/emp/GetassetById/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function PostAssets(body) {
  try {
    const response = await fetch(`/emp/assets`, {
      method: "POST",
      // body:data,
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 400) {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    } else if (response.status === 500) {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
    {
      return response;
    }
  } catch (e) {
    throw e;
  }
}
export async function GetSubcategoriesById(id) {
  let response;
  try {
    response = await fetch(`/v1/GetSubcategories?categoryId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function Idodelete(id) {
  let response;
  try {
    response = await fetch(`/emp/asset/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function idoUpdate(body, _id) {
  let response;
  try {
    response = await fetch(`/emp/asset/${_id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function inoUpdate(body, id) {
  let response;
  try {
    response = await fetch(`/emp/asset/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function assetActive(_id) {
  let response;
  try {
    response = await fetch(`/emp/asset/activate/${_id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function assetDeactive(_id) {
  let response;
  try {
    response = await fetch(`/emp/asset/deactivate/${_id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });
  } catch (e) {
    throw new Error(e.message);
  }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetempAssetsByFilter(id, body) {
  let response;
  try {
    response = await fetch("/emp/assets/collection/filter/" + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

//-------------------------COCK FIGHTING----------------------
//-------------------------EVENTS----------------------
export async function CreateEvent(body, authToken) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/event/createEvent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetEvent(id, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/event/details/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetEvents(query, token) {
  let response;
  try {
    response = await fetch(
      COCK_FIGHTING_API_URL + "/v1/event/allEvents" + query,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
      }
    );
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetSelectEvents(query) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/event/select", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function VerifyEvent(id, body) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/event/update/" + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function eventcount(token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/event/count", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
//-------------------------Players----------------------



export async function GetPlayers(query, body, token) {
  let response;
  try {
    response = await fetch(
      COCK_FIGHTING_API_URL + "/v1/user/admin/allusers" + query,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}


export async function GetPlayerbyid(id) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/user/admin/detailsById/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetPlayerTranscationbyid(uid) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/transaction/" + uid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetPlayerbalancebyid(uid) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/wallet/admin/balance/" + uid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function usercount(token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/user/count", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token

      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

//-------------------------FIGHTS----------------------

export async function GetFight(id) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/fight/details/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}


export async function fightcount(token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/fight/count", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token

      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}


export async function fightdetail(body) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/fight/admin/fights", {

      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
    );
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetFights(query, body, token) {
  let response;
  try {
    response = await fetch(
      COCK_FIGHTING_API_URL + "/v1/fight/allFights" + query,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function VerifyFight(id, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/fight/update/" + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function EndFight(body, authToken) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/fight/end", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function EndStaking(body, authToken, query) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/fight/" + query, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function CreateFights(body, authToken) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/fight/createFights", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    return message.error;
  }
}
//-------------------------DEPOSITS----------------------

export async function GetDeposit(id) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/transaction/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}


export async function GetDepositcount(token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/transaction/count", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token

      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetIncome(token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/income", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetDeposits(query, body, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/deposits" + query, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(body)
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAllDeposits(token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/deposits/getAll", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function VerifyDeposit(id, body) {
  let response;
  try {
    response = await fetch(
      COCK_FIGHTING_API_URL + "/v1/transaction/update/" + id,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetRewards(id, query, body) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/reward/" + id + query, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
//-------------------------STAKE----------------------
export async function GetStake(id, name, query, body) {
  let response;
  try {
    response = await fetch(
      COCK_FIGHTING_API_URL + "/v1/stake/" + id + "/" + name + query,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetAllStake(query, body, token) {
  let response;
  try {
    response = await fetch(
      COCK_FIGHTING_API_URL + "/v1/stake/admin/allHistory" + query,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetdateStake(query, body) {
  let response;
  try {
    response = await fetch(
      COCK_FIGHTING_API_URL + "/v1/stake/date/count" + query,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function stackcount(token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/stake/average", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token

      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}



export async function stackchart(body, token) {
  let response;
  try {
    response = await fetch(
      COCK_FIGHTING_API_URL + "/v1/stake/chart",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetSummary(body, token) {
  let response;
  try {
    response = await fetch(
      COCK_FIGHTING_API_URL + "/v1/summary",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        body: JSON.stringify(body),
      }
    );
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
//-------------------------Arenas----------------------
export async function GetArenas(query) {
  let response;
  try {
    response = await fetch(
      COCK_FIGHTING_API_URL + "/v1/arena/allArenas" + query,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function VerifyArenas(query) {
  let response;
  try {
    response = await fetch(
      COCK_FIGHTING_API_URL + "/v1/arena/allArenas" + query,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function createArenas(query) {
  let response;
  try {
    response = await fetch(
      COCK_FIGHTING_API_URL + "/v1/arena/allArenas" + query,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// Withdraw
export async function GetWithdraws(query, body, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/withdraw/getAll" + query, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(body)
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAllWithdraw(token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/withdraw/getAll", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetWithdraw(id) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/withdraw/details/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}



export async function GetWithdrawcount(token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/withdraw/count", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token

      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function VerifyWithdraw(id) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/withdraw/details/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function UpdateWithdraw(id, status, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/withdraw/status/" + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(status)
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
//Affiliates
export async function GetAllAffiliates() {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/affiliates", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function affiliates(token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/affiliates/count", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token

      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetOperators(query, body, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/transaction/income" + query, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token

      },
      body: JSON.stringify(body)
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetSubOperators(query, body, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/transaction/suboperatorincome" + query, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token

      },
      body: JSON.stringify(body)
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAffiliates(query, body, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/affiliate/getAll" + query, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(body)
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAffiliateByRefId(id) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/affiliate/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function CreateAffiliate(body, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/affiliate/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(body)
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function DeleteAffiliate(id, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/affiliate/delete/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function UpdateAffiliate(id, body, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/affiliate/update/" + id, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(body)
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

export async function GetAffiliate(id, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/affiliateById/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
//CockPick
export async function GetCockPicks(query, body, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/cockpick/getAll" + query, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(body)
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetCockPick(token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/cockpick", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function VerifyCockPick(token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/cockpick", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
// TFA
export async function GetAuthenticatorQr(accessToken, uid) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + `/v1/2FA/admin/authenticatorQr?uid=${uid}`, {
      headers: {
        Authorization: accessToken,
      },
    });
  } catch (e) {
    console.log('get authenticator exception', e);
  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }

}

export async function Getis2FAEnabled(email) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + `/v1/2FA/is2FAEnabled?email=${email}`, {});
  } catch (e) {
    console.log('get is2FA exception', e);
  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }

}

export async function PostEnable2FA(accessToken, uid, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + `/v1/2FA/admin/enable2FA?uid=${uid}&token=${token}`, {
      method: 'post',
      headers: {
        Authorization: accessToken,
      },
    });
  } catch (e) {
    return e.response;
  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }

}

export async function PostDisable2FA(accessToken, uid, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + `/v1/2FA/admin/disable2FA?uid=${uid}&token=${token}`, {
      method: 'post',
      headers: {
        Authorization: accessToken,
      },
    });
  } catch (e) {
    return e.response;
  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }

}

export async function PostVerify2FA(email, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + `/v1/2FA/verify2FA?email=${email}&token=${token}`, {
      method: 'POST',
    });
  } catch (e) {
    return e.response;
  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }

}
// Media Services
export async function GetMediaServices(accessToken) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + `/v1/mediaservices`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    });
  } catch (e) {
    return e.response;
  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }

}

export async function createMediaServices(body, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + `/v1/mediaservices`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body)
    });
  } catch (e) {
    return e.response;
  }
  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }

}

// Solana
export async function GetSolanas(query, body, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/solana/getAll" + query, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(body)
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetSolana(id) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/solana/details/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function VerifySolana(id) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/solana/details/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAllSolana(token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/solana/getAll", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function UpdateSolana(id, status) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/solana/status/" + id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(status)
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}

// Referral
export async function GetReferrals(query, body, token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/referral/getAll" + query, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(body)
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetAllReferral(token) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/referral/getAll", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
export async function GetReferral(id) {
  let response;
  try {
    response = await fetch(COCK_FIGHTING_API_URL + "/v1/referral/details/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) { }

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
